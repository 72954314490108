<template>
  <div :key="'departmentEmployee' + department.id" v-for="department in departments">
    <span :class="'badge rounded-pill bg-' + getRoleClass(department.employeeDepartmentPivot.role)">{{ getRoleName(department.employeeDepartmentPivot.role) }}/{{ department.name }}</span>
  </div>
</template>

<script>
export default {
  name: "DepartmentBadge",
  props: {
    departments: Array
  },
  methods: {
    getRoleName(role) {
      switch (role) {
        case "manager":
          return "Trưởng phòng";
        case "employee":
          return "Nhân viên";
        default:
          return "";
      }
    },
    getRoleClass(role) {
      switch (role) {
        case "manager":
          return "danger";
        case "employee":
          return "info";
        default:
          return "";
      }
    }
  }
}
</script>
