<template>
  <select class="form-control" v-model="value">
    <option :value="department.code" :key="unique + department.code" v-for="department in departments">{{ department.name }}</option>
  </select>
</template>

<script>

export default {
  name: "TaskDepartmentSelect",
  props: {
    unique: String,
    code: {
      type: String,
      default: ""
    },
    departments: Array
  },
  data() {
    return {
      value: ""
    }
  },
  methods: {
    setCurrentValue(value) {
      this.value = value;
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('update:code', newVal)
      }
    },
    code: {
      handler(newVal) {
        this.setCurrentValue(newVal);
      }
    }
  }
}
</script>
