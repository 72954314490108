<template>
  <span :class="'badge rounded-pill bg-' + getClass()">{{ getName() }}</span>
</template>

<script>
import {getTaskStatusClass, getTaskStatusName} from "@/core/services/utils.service";

export default {
  name: "TaskStatus",
  props: {
    status: String
  },
  methods: {
    getName() {
      return getTaskStatusName(this.status)
    },
    getClass() {
      return getTaskStatusClass(this.status);
    }
  }
}
</script>
