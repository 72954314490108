<template>
  <div v-if="roles">
    <div class="form-check" v-for="(role, index) in roles" :key="unique + 'DepartmentRole' + index">
      <input type="radio"
             v-model="value"
             :value="role.value"
             :id="unique+ 'DepartmentRole' + role.value"
             class="form-check-input">
      <label class="form-check-label" :for="unique + 'DepartmentRole' + role.value">{{ role.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepartmentRoles",
  props: {
    selectedDepartments: Array,
    unique: {
      type: String,
      default: ""
    },
    role: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      value: "",
      roles: [
        {name: "Trưởng phòng", value: "manager"},
        {name: "Nhân viên", value: "employee"}
      ]
    }
  },
  methods: {
    getSelectedValue() {
      if (this.selectedDepartments) {
        for (let i = 0; i < this.selectedDepartments.length; i++) {
          for (let j = 0; j < this.roles.length; j++) {
            if (this.roles[j].value === this.selectedDepartments[i].employeeDepartmentPivot.role) {
              this.value = this.roles[j].value;
              return;
            }
          }
        }
      }

      return false;
    },
    setCurrentValue(value) {
      this.value = value;
    }
  },
  created() {
    this.getSelectedValue();
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('update:role', newVal)
      }
    },
    role: {
      handler(newVal) {
        this.setCurrentValue(newVal);
      }
    },
    selectedDepartments: {
      handler() {
        this.getSelectedValue();
      }
    }
  }
}
</script>
