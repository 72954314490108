<template>
  <div class="py-6" style="background: linear-gradient(270deg, #9D4EFF 0%, #782AF4 100%);">
  </div>
  <div class="bg-white shadow-sm">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <div class="d-md-flex align-items-center justify-content-between bg-white  pt-3 pb-3 pb-lg-5">
            <div class="d-md-flex align-items-center text-lg-start text-center ">
              <div class="me-3 mt-n8">
                <router-link :to="{name: 'Home'}" :title="agency.name">
                  <img
                      :src="agency && agency.thumbnail && agency.thumbnail.id ? agency.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                      :alt="agency.name"
                      class="avatar-xxl rounded bg-white object-fit-cover">
                </router-link>
              </div>
              <div class="mt-3 mt-md-0">
                <h1 class="mb-0 fw-bold me-3 ">{{ agency.name }}</h1>
              </div>
            </div>
            <div class="mt-3 mt-lg-0 text-lg-start text-center">
              <router-link v-if="tab === 'school'" class="btn btn-primary float-end text-nowrap"
                           :to="{name: 'AdminAddSchool'}">
                <i class="fe fe-plus-circle"></i> Thêm trường học
              </router-link>
              <button v-if="tab === 'employee'" data-bs-toggle="modal" data-bs-target="#addEmployeeModal"
                      class="btn btn-primary float-end text-nowrap"><i class="fe fe-plus-circle"></i> Thêm nhân viên
              </button>
              <button v-if="tab === 'department'" data-bs-toggle="modal" data-bs-target="#addDepartmentModal"
                      class="btn btn-primary float-end text-nowrap"><i class="fe fe-plus-circle"></i> Thêm phòng, ban
              </button>
              <button v-if="tab === 'task'" data-bs-toggle="modal" data-bs-target="#addTaskModal"
                      class="btn btn-primary float-end text-nowrap"><i class="fe fe-plus-circle"></i> Thêm công việc
              </button>
            </div>
          </div>

          <ul class="nav nav-lt-tab" id="pills-tab" role="tablist">
            <li class="nav-item ms-0" role="presentation"
                v-if="user && user.role !== 'reviewer_admin' && user.role !== 'reviewer'">
              <a v-on:click="setTab('task')" :class="tab === 'task' ? 'nav-link active' : 'nav-link'"
                 id="pills-task-tab" data-bs-toggle="pill" href="#pills-task" role="tab"
                 aria-controls="pills-task" aria-selected="false">Công việc</a>
            </li>
            <li class="nav-item ms-0" role="presentation"
                v-if="user && (user.role === 'admin' || user.agency_role === 'admin')">
              <a v-on:click="setTab('school')" :class="tab === 'school' ? 'nav-link active' : 'nav-link'"
                 id="pills-school-tab" data-bs-toggle="pill" href="#pills-school" role="tab"
                 aria-controls="pills-school" aria-selected="false">Trường học</a>
            </li>
            <li class="nav-item ms-0" role="presentation"
                v-if="user && (user.role === 'admin' || user.agency_role === 'admin')">
              <a v-on:click="setTab('employee')" :class="tab === 'employee' ? 'nav-link active' : 'nav-link'"
                 id="pills-employee-tab" data-bs-toggle="pill" href="#pills-employee" role="tab"
                 aria-controls="pills-employee" aria-selected="false">Nhân viên</a>
            </li>
            <li class="nav-item ms-0" role="presentation"
                v-if="user && (user.role === 'admin' || user.agency_role === 'admin')">
              <a v-on:click="setTab('department')" :class="tab === 'department' ? 'nav-link active' : 'nav-link'"
                 id="pills-department-tab" data-bs-toggle="pill" href="#pills-department" role="tab"
                 aria-controls="pills-department" aria-selected="false">Phòng ban</a>
            </li>
            <li class="nav-item ms-0" role="presentation"
                v-if="user && (user.role === 'admin' || user.agency_role === 'admin' || user.role === 'reviewer_admin' || user.role === 'reviewer')">
              <a v-on:click="setTab('submit')" :class="tab === 'submit' ? 'nav-link active' : 'nav-link'"
                 id="pills-submit-tab" data-bs-toggle="pill" href="#pills-submit" role="tab"
                 aria-controls="pills-department" aria-selected="false">Bài nộp</a>
            </li>
            <li class="nav-item ms-0" role="presentation"
                v-if="user && (user.role === 'admin' || user.agency_role === 'admin')">
              <a v-on:click="setTab('setting')" :class="tab === 'setting' ? 'nav-link active' : 'nav-link'"
                 id="pills-setting-tab" data-bs-toggle="pill" href="#pills-setting" role="tab"
                 aria-controls="pills-setting" aria-selected="false">Cài đặt</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="tab-content" id="pills-tabContent">
            <div v-if="user && (user.role === 'admin' || user.agency_role === 'admin')"
                 :class="tab === 'school' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-school"
                 role="tabpanel" aria-labelledby="pills-school-tab">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="card pb-4">
                    <div class="card-header border-bottom-0">
                      <form class="d-flex align-items-center">
                        <input type="search" v-model="schoolFilter.name" class="form-control me-4"
                               placeholder="Tên trường">
                        <select class="form-control" v-model="schoolFilter.status">
                          <option value="">-- Trạng thái --</option>
                          <option value=1>Đang hoạt động</option>
                          <option value=0>Tạm khóa</option>
                        </select>
                      </form>
                    </div>
                    <table class="table list">
                      <thead class="table-light">
                      <tr>
                        <th scope="col">Tên trường</th>
                        <th scope="col">Trạng thái</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(school, index) in schools.data" :key="'school' + index">
                        <td class="align-middle">
                          <div class="d-lg-flex align-items-center">
                            <div class="thumbnail">
                              <a :href="getSchoolDomain(school.subdomain)">
                                <img
                                    :src="school.thumbnail ? school.thumbnail.link : '/assets/images/brand/logo/erobo.jpg'"
                                    alt="" class="rounded">
                              </a>
                            </div>
                            <div class="ms-lg-3 mt-2 mt-lg-0">
                              <h4 class="mb-1 text-primary-hover">
                                <a :href="getSchoolDomain(school.subdomain)">
                                  {{ school.name }}
                                </a>
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span class="badge rounded-pill bg-success" v-if="school.status === 1">Đang hoạt động</span>
                          <span class="badge rounded-pill bg-danger" v-if="school.status === 0">Tạm dừng</span>
                        </td>
                        <td class="text-muted align-middle border-top-0">
                          <span class="dropdown dropstart">
                            <a class="text-muted text-decoration-none" href="#" role="button" id="comboDropdown"
                               data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <span class="dropdown-menu" aria-labelledby="comboDropdown" style="">
                              <span class="dropdown-header">Thao tác</span>
                              <router-link class="dropdown-item"
                                           :to="{name: 'AdminSchoolDetail', params: {code: school.code}}">
                                <i class="fe fe-settings dropdown-item-icon"></i> Quản trị
                              </router-link>
                              <a :href="getSchoolDomain(school.subdomain)" class="dropdown-item">
                                <i class="fe fe-eye dropdown-item-icon"></i> Xem
                              </a>
                              <router-link class="dropdown-item"
                                           :to="{name: 'AdminSchoolEdit', params: {code: school.code}}">
                                <i class="fe fe-edit dropdown-item-icon"></i> Sửa
                              </router-link>
                              <a class="dropdown-item" v-on:click="setCurrentSchool(school)"
                                 href="#" data-bs-toggle="modal"
                                 data-bs-target="#removeSchoolModal"><i
                                  class="fe fe-trash-2 dropdown-item-icon"></i> Xóa</a>
                            </span>
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <v-pagination
                        v-if="schools.paginatorInfo.lastPage > 1"
                        v-model="schools.paginatorInfo.currentPage"
                        :pages="schools.paginatorInfo.lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="loadAgency"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="user && user.role !== 'reviewer_admin' && user.role !== 'reviewer'"
                 :class="tab === 'employee' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-employee"
                 role="tabpanel" aria-labelledby="pills-employee-tab">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="card pb-4">
                    <div class="card-header border-bottom-0">
                      <form class="d-flex align-items-center">
                        <input type="search" v-model="employeeFilter.name" class="form-control me-4"
                               placeholder="Họ tên">
                        <input type="search" v-model="employeeFilter.email" class="form-control me-4"
                               placeholder="Email">
                        <select class="form-control me-4" v-model="employeeFilter['agency_employee.role']">
                          <option value="">-- Vai trò --</option>
                          <option value="admin">Admin</option>
                          <option value="sale">Sale</option>
                          <option value="support">CSKH</option>
                        </select>
                        <select class="form-control" v-model="employeeFilter['agency_employee.status']">
                          <option value="">-- Trạng thái --</option>
                          <option value=1>Đang hoạt động</option>
                          <option value=0>Tạm khóa</option>
                        </select>
                      </form>
                    </div>
                    <table class="table mb-4 text-nowrap list">
                      <thead class="table-light">
                      <tr>
                        <th scope="col">Họ tên</th>
                        <th scope="col">Email/SĐT</th>
                        <th scope="col">Phòng ban</th>
                        <th scope="col">Quyền chung</th>
                        <th scope="col">Trạng thái</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(employee, index) in employees.data" :key="'employee' + index">
                        <td class="align-middle">
                          <div class="d-lg-flex align-items-center">
                            <div class="thumbnail">
                              <router-link :to="{name: 'AdminUserDetail', params: {uid: employee.uid}}">
                                <img
                                    :src="employee.thumbnail ? employee.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                                    alt="" class="avatar">
                              </router-link>
                            </div>
                            <div class="ms-lg-3 mt-2 mt-lg-0">
                              <h4 class="mb-1 text-primary-hover">
                                <router-link class="text-inherit"
                                             :to="{name: 'AdminUserDetail', params: {uid: employee.uid}}">
                                  {{ employee.name }}
                                </router-link>
                              </h4>
                              <span class="text-inherit">{{ employee.created_at }}</span>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span class="d-block">{{ employee.email }}</span>
                          <span class="d-block">{{ employee.phone }}</span>
                        </td>
                        <td class="align-middle">
                          <department-badge v-if="employee.departments && employee.departments.data"
                                            :departments="employee.departments.data"/>
                        </td>
                        <td class="align-middle">
                          <a v-on:click="setCurrentEmployeeChangeRole(employee)"
                             href="#" data-bs-toggle="modal"
                             data-bs-target="#changeEmployeeRoleModal">
                            <span class="badge rounded-pill bg-danger"
                                  v-if="employee.employeeAgencyPivot.role === 'admin'">Admin</span>
                          </a>
                          <a v-on:click="setCurrentEmployeeChangeRole(employee)"
                             href="#" data-bs-toggle="modal"
                             data-bs-target="#changeEmployeeRoleModal">
                            <span class="badge rounded-pill bg-info"
                                  v-if="employee.employeeAgencyPivot.role === 'employee'">Nhân viên</span>
                          </a>
                        </td>
                        <td class="align-middle">
                          <span class="badge rounded-pill bg-success" v-if="employee.employeeAgencyPivot.status === 1">Đang
                            hoạt động</span>
                          <span class="badge rounded-pill bg-danger" v-if="employee.employeeAgencyPivot.status === 0">Tạm
                            ngưng</span>
                        </td>
                        <td class="text-muted align-middle border-top-0">
                          <span class="dropdown dropstart">
                            <a class="text-muted text-decoration-none" href="#" role="button" id="userDropdown1"
                               data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <span class="dropdown-menu" aria-labelledby="userDropdown1" style="">
                              <span class="dropdown-header">Thao tác</span>
                              <router-link class="dropdown-item"
                                           :to="{name: 'AdminUserDetail', params: {uid: employee.uid}}">
                                <i class="fe fe-eye dropdown-item-icon"></i> Xem
                              </router-link>
                              <a class="dropdown-item" v-on:click="setCurrentEmployee(employee)"
                                 href="#" data-bs-toggle="modal"
                                 data-bs-target="#updateEmployeeModal"><i
                                  class="fe fe-edit dropdown-item-icon"></i>Sửa</a>
                              <a class="dropdown-item" v-on:click="setCurrentChangeEmployeeRole(employee)"
                                 href="#" data-bs-toggle="modal"
                                 data-bs-target="#changeEmployeeRoleModal"><i
                                  class="fe fe-user dropdown-item-icon"></i>Thay đổi quyền</a>
                              <a class="dropdown-item" v-on:click="setCurrentEmployee(employee)"
                                 href="#" data-bs-toggle="modal"
                                 data-bs-target="#removeEmployeeAgencyModal"><i
                                  class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                            </span>
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <v-pagination
                        v-if="employees.paginatorInfo.lastPage > 1"
                        v-model="employees.paginatorInfo.currentPage"
                        :pages="employees.paginatorInfo.lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="loadEmployees"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="user && (user.role === 'admin' || user.agency_role === 'admin')"
                 :class="tab === 'department' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-employee"
                 role="tabpanel" aria-labelledby="pills-department-tab">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="card pb-4">
                    <div class="card-header border-bottom-0">
                      <form class="d-flex align-items-center">
                        <input type="search" v-model="departmentFilter.name" class="form-control me-4"
                               placeholder="Tên phòng ban">
                      </form>
                    </div>
                    <table class="table mb-4 text-nowrap list">
                      <thead class="table-light">
                      <tr>
                        <th scope="col">Phòng ban</th>
                        <th scope="col">Nhân sự</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(department, index) in departments.data" :key="'department' + index">
                        <td class="align-middle">
                          {{ department.name }}
                        </td>
                        <td class="align-middle">
                          {{
                            department.employees && department.employees.paginatorInfo ? department.employees.paginatorInfo.total : 0
                          }}
                        </td>
                        <td class="text-muted align-middle border-top-0">
                          <span class="dropdown dropstart">
                            <a class="text-muted text-decoration-none" href="#" role="button" id="userDropdown1"
                               data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <span class="dropdown-menu" aria-labelledby="userDropdown1" style="">
                              <span class="dropdown-header">Thao tác</span>
                              <a class="dropdown-item" v-on:click="setCurrentDepartment(department)"
                                 href="#" data-bs-toggle="modal"
                                 data-bs-target="#removeDepartmentAgencyModal"><i
                                  class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                            </span>
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="user && user.role !== 'reviewer_admin' && user.role !== 'reviewer'"
                 :class="tab === 'task' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-task"
                 role="tabpanel" aria-labelledby="pills-task-tab">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="card pb-4">
                    <div class="card-header border-bottom-0">
                      <form class="d-flex align-items-center">
                        <input type="search" v-model="taskFilter.name" class="form-control me-4"
                               placeholder="Tên công việc">
                        <input v-for="(item, index) in filterFields" :key="'taskFilter' + index" type="search"
                               v-model="item.value" class="form-control me-4" :placeholder="item.key">
                        <select class="form-control me-4" v-model="taskFilter.department_id">
                          <option value="">-- Phòng tiếp nhận --</option>
                          <template v-if="departments && departments.data">
                            <option :value="department.id" :key="'taskFilterDepartment' + department.id"
                                    v-for="department in departments.data">{{ department.name }}
                            </option>
                          </template>
                        </select>
                        <task-status-select class="me-4" unique="taskFilterStatus" v-model:status="taskFilter.status"
                                            :showEmpty="true"/>
                        <task-assignee-select :show-empty="true" field="id"
                                              :departments="departments && departments.data ? departments.data : []"
                                              v-model:change="taskFilter.assignee_id" unique="taskFilterAssignee"/>
                      </form>
                    </div>
                    <table class="table mb-4 text-nowrap list">
                      <thead class="table-light">
                      <tr>
                        <th scope="col">Công việc</th>
                        <th scope="col" v-for="(item, index) in listFields" :key="'listFieldsTH' + index">{{
                            item.key
                          }}
                        </th>
                        <th scope="col">Trạng thái</th>
                        <th scope="col">Phòng tiếp nhận</th>
                        <th scope="col">Người xử lý</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(task, index) in tasks.data" :key="'task' + index">
                        <td class="align-middle">
                          <router-link :to="{name: 'AdminTaskDetail', params: {code: task.code}}">
                            {{ task.name }}
                          </router-link>
                        </td>
                        <td class="align-middle" v-for="(item, index) in listFields" :key="'listFieldsTD' + index">
                          {{ getValueByMetaKey(item.key, task.metaData.data) }}
                        </td>
                        <td class="align-middle">
                          <a href="#" v-on:click="setCurrentTaskStatus(task); setCurrentTask(task)"
                             data-bs-toggle="modal" data-bs-target="#changeTaskStatusModal">
                            <task-status :status="task.status"/>
                          </a>
                        </td>
                        <td class="align-middle">
                          <a href="#" v-on:click="setCurrentTaskDepartment(task); setCurrentTask(task)"
                             data-bs-toggle="modal" data-bs-target="#changeTaskDepartmentModal">
                            <task-department-badge :department="task.department ? task.department.name : ''"/>
                          </a>
                        </td>
                        <td class="align-middle">
                          <a href="#" v-on:click="setCurrentTaskAssignee(task); setCurrentTask(task)"
                             data-bs-toggle="modal" data-bs-target="#changeTaskAssigneeModal">
                            <task-assignee :assignee="task.assignee ? task.assignee.name: 'Chưa ai tiếp nhận'"/>
                          </a>
                        </td>
                        <td class="text-muted align-middle border-top-0">
                          <span class="dropdown dropstart">
                            <a class="text-muted text-decoration-none" href="#" role="button" id="userDropdown3"
                               data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                              <i class="fe fe-more-vertical"></i>
                            </a>
                            <span class="dropdown-menu" aria-labelledby="userDropdown3" style="">
                              <span class="dropdown-header">Thao tác</span>
                              <router-link class="dropdown-item"
                                           :to="{name: 'AdminTaskDetail', params: {code: task.code}}">
                                <i class="fe fe-eye dropdown-item-icon"></i>Xem
                              </router-link>
                              <a class="dropdown-item" v-on:click="setCurrentTask(task)"
                                 href="#" data-bs-toggle="modal"
                                 data-bs-target="#updateTaskModal"><i
                                  class="fe fe-edit dropdown-item-icon"></i>Sửa</a>
                              <a class="dropdown-item" v-on:click="setCurrentTask(task)"
                                 href="#" data-bs-toggle="modal"
                                 data-bs-target="#deleteTaskModal"><i
                                  class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                            </span>
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <v-pagination
                        v-if="tasks.paginatorInfo.lastPage > 1"
                        v-model="tasks.paginatorInfo.currentPage"
                        :pages="tasks.paginatorInfo.lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="loadTasks"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="user && (user.role === 'reviewer_admin' || user.role === 'reviewer' || user.role === 'admin')"
                 :class="tab === 'submit' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-submit"
                 role="tabpanel" aria-labelledby="pills-submit-tab">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="card pb-4">
                    <SubmitLesson v-on:dataChange="loadSubmits"
                                  v-on:currentPageChange="currentPage => {submits.paginatorInfo.currentPage = currentPage; loadSubmits()}"
                                  v-on:filterChange="filter => {submitFilter = filter; submits.paginatorInfo.currentPage = 1; loadSubmits()}"
                                  :data="submits.data"
                                  role="reviewer"
                                  :paginatorInfo="submits.paginatorInfo"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="user && (user.role === 'admin' || user.agency_role === 'admin')"
                 :class="tab === 'setting' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-setting"
                 role="tabpanel" aria-labelledby="pills-user-tab">
              <div class="col-lg-12 col-md-12 col-12">
                <div v-for="setting in settings" :key="setting.meta_key" class="mb-4">
                  <div class="d-flex align-items-center justify-content-between mb-4">
                    <h2 class="mb-0">{{ setting.name }}</h2>
                    <button class="btn btn-primary btn-sm float-end"
                            v-on:click="updateAgencyMetaData(setting.meta_key, setting.meta_value)"><i
                        class="fe fe-save"/> Cập nhật
                    </button>
                  </div>
                  <div>
                    <textarea class="form-control" v-model="setting.meta_value" rows="10"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="addEmployeeModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <form v-on:submit.prevent="addEmployee">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Thêm nhân viên
            </h4>
            <button type="button" id="addEmployeeModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-8">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="addEmployeeName" class="form-label">Họ tên (*)</label>
                    <input :disabled="detectedUser" v-model="employee.name" id="addEmployeeName" class="form-control"
                           type="text" required/>
                  </div>
                  <div class="col-md-6">
                    <label for="email" class="form-label">Email (*)</label>
                    <div class="loadingable">
                      <input v-model="employee.email" v-on:blur="findUserByIdentity(employee.email)" id="email"
                             class="form-control" type="email"
                             placeholder="dán email để tự động detect user"
                             required/>
                      <loading v-if="detectedUserLoading"/>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="phone" class="form-label">Số ĐT (*)</label>
                    <div class="loadingable">
                      <input v-model="employee.phone" v-on:blur="findUserByIdentity(employee.phone)" id="phone"
                             class="form-control" type="text"
                             placeholder="dán sđt để tự động detect user"
                             required/>
                      <loading v-if="detectedUserLoading"/>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="password" class="form-label">Password (*)</label>
                    <input :disabled="detectedUser" v-model="employee.password" id="password" class="form-control"
                           type="text"
                           required/>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Vai trò đối với toàn đại lý (*)</label>
                    <agency-roles unique="addEmployee" v-model:role="employee.role"/>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <label class="form-label">Phòng ban (*)</label>
                        <departments unique="addEmployee" v-model:code="employee.department_code"
                                     :departments="departments.data"/>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Vị trí (*)</label>
                        <department-roles unique="addEmployee" v-model:role="employee.department_role"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="addEmployeeMessage !== ''">
                    <div class="alert alert-danger" role="alert">
                      {{ addEmployeeMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <Thumbnail purpose="setThumbnailForUser" v-on:setThumbnail="handleSetThumbnailEvent"
                           :thumbnail="employee.thumbnail"/>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Thêm nhân viên</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button type="button" class="d-none" id="addEmployeeModalOpen" data-bs-toggle="modal"
          data-bs-target="#addEmployeeModal"></button>

  <div class="modal fade" id="updateEmployeeModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <form v-on:submit.prevent="updateEmployee">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Sửa nhân viên
            </h4>
            <button type="button" id="updateEmployeeModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-8">
                <div class="row g-3">
                  <div class="col-md-6">
                    <label for="updateEmployeeName" class="form-label">Họ tên (*)</label>
                    <input v-model="employee.name" id="updateEmployeeName" class="form-control" type="text" required/>
                  </div>
                  <div class="col-md-6">
                    <label for="updateEmployeeEmail" class="form-label">Email (*)</label>
                    <input v-model="employee.email" id="updateEmployeeEmail" class="form-control" type="email"
                           required/>
                  </div>
                  <div class="col-md-6">
                    <label for="updateEmployeePhone" class="form-label">Số ĐT (*)</label>
                    <input v-model="employee.phone" id="updateEmployeePhone" class="form-control" type="text" required/>
                  </div>
                  <div class="col-md-6">
                    <label for="updateEmployeePassword" class="form-label">Password (*)</label>
                    <input v-model="employee.password" id="updateEmployeePassword" class="form-control" type="text"/>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Vai trò đối với toàn đại lý (*)</label>
                    <agency-roles unique="updateEmployee" :selected-role="employee.role" v-model:role="employee.role"/>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <label class="form-label">Phòng ban (*)</label>
                        <departments unique="updateEmployee"
                                     :selected-departments="employee.departments && employee.departments.data ? employee.departments.data : []"
                                     v-model:code="employee.department_code" :departments="departments.data"/>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Vị trí (*)</label>
                        <department-roles unique="updateEmployee"
                                          :selected-departments="employee.departments && employee.departments.data ? employee.departments.data : []"
                                          v-model:role="employee.department_role"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="updateEmployeeMessage">
                    <div class="alert alert-danger" role="alert">
                      {{ updateEmployeeMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <Thumbnail purpose="setThumbnailForUser" v-on:setThumbnail="handleSetThumbnailEvent"
                           :thumbnail="employee.thumbnail"/>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Cập nhật nhân viên</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button type="button" class="d-none" id="updateEmployeeModalOpen" data-bs-toggle="modal"
          data-bs-target="#addEmployeeModal"></button>

  <div class="modal fade" id="assignEmployeeModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <form v-on:submit.prevent="assignEmployee" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Phát hiện nhân viên
            </h4>
            <button type="button" id="assignEmployeeModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="suggestEmployee.uid">
              <div class="row">
                <div class="col-md-5">
                  <div class="thumbnail">
                    <img
                        :src="suggestEmployee.thumbnail ? suggestEmployee.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                        class="avatar"/>
                  </div>
                </div>
                <div class="col-md-7">
                  <table class="table">
                    <tbody>
                    <tr>
                      <td>Họ tên</td>
                      <td>{{ suggestEmployee.name }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ suggestEmployee.email }}</td>
                    </tr>
                    <tr>
                      <td>SĐT</td>
                      <td>{{ suggestEmployee.phone }}</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="text-center mt-4">
                    <label class="form-label fw-bold">Chọn vai trò (*)</label>
                    <div>
                      <div class="d-inline-flex">
                        <div class="form-check me-3">
                          <input type="radio" id="roleAdmin1" v-model="suggestEmployee.role" value="admin"
                                 class="form-check-input">
                          <label class="form-check-label" for="roleAdmin1">Admin</label>
                        </div>
                        <div class="form-check me-3">
                          <input type="radio" id="roleSale1" v-model="suggestEmployee.role" value="sale"
                                 class="form-check-input">
                          <label class="form-check-label" for="roleSale1">Sale</label>
                        </div>
                        <div class="form-check">
                          <input type="radio" id="roleSupport1" v-model="suggestEmployee.role" value="support"
                                 class="form-check-input">
                          <label class="form-check-label" for="roleSupport1">CSKH</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Thêm nhân viên</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button type="button" class="d-none" id="assignEmployeeModalOpen" data-bs-toggle="modal"
          data-bs-target="#assignEmployeeModal"></button>

  <div class="modal fade" id="changeEmployeeRoleModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="changeEmployeeRole" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Thay đổi quyền
            </h4>
            <button type="button" id="changeEmployeeRoleModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label class="form-label">Vai trò (*)</label>
                <div>
                  <agency-roles unique="changeEmployeeRole"
                                :selected-role="currentEmployeeChangeRole.employeeAgencyPivot.role"
                                v-model:role="currentEmployeeChangeRole.employeeAgencyPivot.role"/>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Thay đổi quyền</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button type="button" class="d-none" id="changeEmployeeRoleModalOpen" data-bs-toggle="modal"
          data-bs-target="#changeEmployeeRoleModal"></button>

  <div class="modal fade" id="addDepartmentModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="addDepartment" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Thêm phòng ban
            </h4>
            <button type="button" id="addDepartmentModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="addDepartmentMessage !== ''">
                    {{ addDepartmentMessage }}
                  </div>
                  <div class="col-md-12">
                    <label for="addDepartmentName" class="form-label">Tên phòng, ban (*)</label>
                    <input v-model="department.name" id="addDepartmentName" class="form-control" type="text" required/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Thêm mới</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button type="button" class="d-none" id="addDepartmentModalOpen" data-bs-toggle="modal"
          data-bs-target="#addDepartmentModal"></button>

  <div class="modal fade" id="addTaskModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <form v-on:submit.prevent="addTask" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Thêm công việc
            </h4>
            <button type="button" id="addTaskModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="addTaskMessage !== ''">
                    {{ addTaskMessage }}
                  </div>
                  <div class="col-md-6">
                    <label for="addTaskName" class="form-label">Tên công việc</label>
                    <input v-model="task.name" id="addTaskName" class="form-control" type="text" required/>
                  </div>
                  <div class="col-md-6">
                    <label for="addTaskContent" class="form-label">Mô tả</label>
                    <textarea class="form-control" id="addTaskContent" v-model="task.content"></textarea>
                  </div>
                  <div class="col-md-6" v-for="(item, index) in customFields" :key="'field' + index">
                    <label :for="'addTask' + index" class="form-label">{{ item.key }}</label>
                    <input v-model="item.value" :id="'addTask' + index" class="form-control" type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Thêm công việc</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="updateTaskModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <form v-on:submit.prevent="updateTask" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Sửa công việc
            </h4>
            <button type="button" id="updateTaskModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="updateTaskMessage !== ''">
                    {{ updateTaskMessage }}
                  </div>
                  <div class="col-md-6">
                    <label for="updateTaskName" class="form-label">Tên công việc</label>
                    <input v-model="currentTask.name" id="updateTaskName" class="form-control" type="text" required/>
                  </div>
                  <div class="col-md-6">
                    <label for="updateTaskContent" class="form-label">Mô tả</label>
                    <textarea class="form-control" id="updateTaskContent" v-model="currentTask.content"></textarea>
                  </div>
                  <div class="col-md-6" v-for="(item, index) in customFields" :key="'field' + index">
                    <label :for="'updateTask' + index" class="form-label">{{ item.key }}</label>
                    <input v-model="item.value" :id="'updateTask' + index" class="form-control" type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Cập nhật</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="changeTaskStatusModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="changeTaskStatus" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Thay đổi trạng thái công việc
            </h4>
            <button type="button" id="changeTaskStatusModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="changeStatusTaskMessage !== ''">
                    {{ changeStatusTaskMessage }}
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Trạng thái</label>
                    <task-status-select v-model:status="taskStatus" unique="changeStatusTask"/>
                    <label class="form-label mt-3" for="changeStatusTaskComment">Ghi chú</label>
                    <textarea class="form-control" v-model="changeTaskStatusComment"
                              id="changeStatusTaskComment"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Thay đổi trạng thái</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button type="button" class="d-none" id="changeStatusTaskModalOpen" data-bs-toggle="modal"
          data-bs-target="#addEmployeeModal"></button>

  <div class="modal fade" id="changeTaskDepartmentModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="changeTaskDepartment" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Chuyển giao công việc
            </h4>
            <button type="button" id="changeTaskDepartmentModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="changeTaskDepartmentMessage !== ''">
                    {{ changeTaskDepartmentMessage }}
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Phòng muốn chuyển giao</label>
                    <task-department-select :departments="departments && departments.data ? departments.data : []"
                                            v-model:code="changeTaskDepartmentDepartmentCode"
                                            unique="changeTaskDepartment"/>
                    <label class="form-label mt-3" for="changeStatusTaskComment">Ghi chú</label>
                    <textarea class="form-control" v-model="changeTaskDepartmentComment"
                              id="changeStatusTaskComment"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Chuyển giao công việc</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="changeTaskAssigneeModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="changeTaskAssignee" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Gán công việc
            </h4>
            <button type="button" id="changeTaskAssigneeModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="changeTaskAssigneeMessage !== ''">
                    {{ changeTaskAssigneeMessage }}
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Chọn người được gán</label>
                    <task-assignee-select :departments="departments && departments.data ? departments.data : []"
                                          field="uid" v-model:change="changeTaskAssigneeUid"
                                          unique="changeTaskAssignee"/>
                    <label class="form-label mt-3" for="changeTaskAssigneeComment">Ghi chú</label>
                    <textarea class="form-control" v-model="changeTaskAssigneeComment"
                              id="changeTaskAssigneeComment"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Gán công việc</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="deleteTaskModal" tabindex="-1" role="dialog"
       aria-labelledby="removeComboModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Xóa công việc
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" role="alert" v-if="deleteTaskMessage !== ''">
            {{ deleteTaskMessage }}
          </div>
          <p>Bạn có chắc chắn muốn xóa <strong>{{ currentTask.name }}</strong>?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger" data-bs-dismiss="modal" type="button"
                  v-on:click="deleteTask">
            Xóa công việc
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeEmployeeAgencyModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Xóa nhân viên
          </h4>
          <button type="button" class="btn-close" id="removeEmployeeAgencyModalClose" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" role="alert" v-if="deleteEmployeeMessage !== ''">
            {{ deleteEmployeeMessage }}
          </div>
          <p>Bạn có chắc chắn muốn xóa nhân viên <strong>{{ employee.name }}</strong>?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger" type="button"
                  v-on:click="removeEmployeeAgency">
            Xóa nhân viên
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeSchoolModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Xóa trường học
          </h4>
          <button type="button" class="btn-close" id="removeSchoolModalClose" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" role="alert" v-if="deleteSchoolMessage !== ''">
            {{ deleteSchoolMessage }}
          </div>
          <p>Bạn có chắc chắn muốn xóa trường học <strong>{{ currentSchool.name }}</strong>?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger" type="button"
                  v-on:click="deleteSchool">
            Xóa trường học
          </button>
        </div>
      </div>
    </div>
  </div>

  <Media v-on:setMedia="handleSetMediaEvent" :purpose="mediaPurpose"/>

  <Modal id="messageModal" :type="modalType" :message="modalMessage" :title="modalTitle"
         :message-detail="modalMessageDetail"/>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Thumbnail from "@/components/Thumbnail";
import Media from "@/components/Media";
import Modal from "@/components/Modal";
import {getToken, getUser} from "@/core/services/jwt.service";
import SubmitLesson from "@/components/SubmitLesson";
import {
  changeFavicon,
  closeModal, getSchoolDomain,
  getWhereCondition,
  getWhereCondition2,
  openModal
} from "@/core/services/utils.service";
import Departments from './departments'
import DepartmentRoles from './departmentRoles';
import AgencyRoles from './agencyRoles';
import Loading from "@/components/Loading";
import DepartmentBadge from "@/components/DepartmentBadge";
import TaskStatus from "@/components/admin/agency/taskStatus";
import TaskDepartmentBadge from "@/components/admin/agency/taskDepartmentBadge";
import TaskAssignee from "@/components/admin/agency/taskAssignee";
import TaskStatusSelect from "@/components/admin/agency/taskStatusSelect";
import TaskDepartmentSelect from "@/components/admin/agency/taskDepartmentSelect";
import TaskAssigneeSelect from "@/components/admin/agency/taskAssigneeSelect";

export default {
  name: "AgencyDetail",
  props: {
    user: Object
  },
  components: {
    TaskAssigneeSelect,
    TaskDepartmentSelect,
    TaskStatusSelect,
    TaskAssignee,
    TaskDepartmentBadge,
    TaskStatus,
    DepartmentBadge,
    Thumbnail,
    Media,
    VPagination,
    Modal,
    Departments,
    DepartmentRoles,
    AgencyRoles,
    SubmitLesson,
    Loading
  },
  data() {
    return {
      agency: {
        code: "",
        name: "",
        status: 0,
        content: "",
        thumbnail: {id: 0}
      },
      tasks: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      employees: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      departments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      schools: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      employee: {
        uid: "",
        name: "",
        email: "",
        phone: "",
        password: "",
        role: "employee",
        department_code: "",
        department_role: "",
        thumbnail: {id: 0}
      },
      detectedUser: null,
      detectedUserLoading: false,
      mediaPurpose: "",
      addEmployeeMessage: "",
      updateEmployeeMessage: "",
      tab: 'task',
      modalMessage: "",
      modalType: "",
      modalMessageDetail: "",
      modalTitle: "",
      schoolFilter: {
        name: "",
        status: ""
      },
      employeeFilter: {
        name: "",
        email: "",
        phone: "",
        "agency_employee.status": "",
        "agency_employee.role": ""
      },
      departmentFilter: {
        name: ""
      },
      taskFilter: {
        name: "",
        email: "",
        phone: "",
        department_id: "",
        assignee_id: "",
        status: ""
      },
      currentUser: {
        name: "",
        employeeAgencyPivot: {
          role: ""
        }
      },
      currentSchool: {
        name: ""
      },
      employeeAgencyCount: {
        admin: 0,
        sale: 0,
        support: 0
      },
      suggestEmployee: {
        name: "",
        uid: "",
        email: "",
        phone: "",
        role: "pupil"
      },
      currentEmployeeChangeRole: {
        uid: "",
        employeeAgencyPivot: {
          role: ""
        }
      },
      task: {
        name: "",
        content: ""
      },
      department: {
        name: ""
      },
      addTaskMessage: "",
      addDepartmentMessage: "",
      updateTaskMessage: "",
      deleteTaskMessage: "",
      changeStatusTaskMessage: "",
      changeTaskStatusComment: "",
      changeTaskDepartmentMessage: "",
      changeTaskDepartmentComment: "",
      changeTaskDepartmentDepartmentCode: "",
      changeTaskAssigneeMessage: "",
      changeTaskAssigneeComment: "",
      changeTaskAssigneeUid: "",
      deleteEmployeeMessage: "",
      deleteSchoolMessage: "",
      currentTask: {
        name: ""
      },
      taskStatus: "",
      currentEmployee: {
        uid: "",
        name: ""
      },
      currentDepartment: {
        name: ""
      },
      settings: {
        _custom_fields: {
          meta_key: "_custom_fields",
          name: "Trường tùy chọn cho công việc (mỗi trường 1 dòng)",
          meta_value: ""
        },
        _filter_fields: {
          meta_key: "_filter_fields",
          name: "Các trường muốn lọc (mỗi trường 1 dòng - tối đa 02 trường)",
          meta_value: ""
        },
        _list_fields: {
          meta_key: "_list_fields",
          name: "Các trường muốn show (mỗi trường 1 dòng)",
          meta_value: ""
        },
        _unique_fields: {
          meta_key: "_unique_fields",
          name: "Các trường không cho phép trùng (mỗi trường 1 dòng)",
          meta_value: ""
        }
      },
      customFields: [],
      filterFields: [],
      listFields: [],
      submits: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      submitFilter: {
        name: "",
        email: "",
        phone: "",
        lesson_name: "",
        is_reviewed: "",
        reviewed_by: ""
      },
    }
  },
  methods: {
    getQueryNameAndParam() {
      let queryName = "";
      let queryNameParam = "";
      if (this.$route.params.code) {
        queryName = "agency";
        queryNameParam = `agency(code:"${this.$route.params.code}")`;
      } else {
        queryName = 'myAgency';
        queryNameParam = 'myAgency';
      }

      return {
        queryName: queryName,
        queryNameParam: queryNameParam
      }
    },
    loadAgency() {
      let queryNameAndParam = this.getQueryNameAndParam();
      let queryName = queryNameAndParam.queryName;
      let queryNameParam = queryNameAndParam.queryNameParam;

      let query = `query {
        ${queryNameParam} {
          id
          code
          name
          status
          content
          thumbnail_id
          thumbnail {
            id
            link
            media_type
          }
          metaData(first: 10, where: {AND: [{column: "meta_key", value:["_custom_fields", "_filter_fields", "_list_fields", "_unique_fields"], operator: IN}]}) {
            data {
              meta_key
              meta_value
            }
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data[queryName]) {
              this.agency = data.data[queryName];
              document.title = this.agency.name;
              let favicon = this.agency.thumbnail ? this.agency.thumbnail.link : '/assets/images/avatar/avatar.jpg'
              changeFavicon(favicon);
              if (this.agency.metaData && this.agency.metaData.data) {
                for (let i = 0; i < this.agency.metaData.data.length; i++) {
                  this.settings[this.agency.metaData.data[i].meta_key].meta_value = this.agency.metaData.data[i].meta_value;
                }
              }
              if (this.settings._custom_fields && this.settings._custom_fields.meta_value) {
                let keys = this.settings._custom_fields.meta_value.split("\n");
                for (let i = 0; i < keys.length; i++) {
                  this.customFields[i] = {
                    key: keys[i],
                    value: ""
                  }
                }
              }
              if (this.settings._filter_fields && this.settings._filter_fields.meta_value) {
                let keys = this.settings._filter_fields.meta_value.split("\n");
                for (let i = 0; i < keys.length; i++) {
                  this.filterFields[i] = {
                    key: keys[i],
                    value: ""
                  }
                }
              }
              if (this.settings._list_fields && this.settings._list_fields.meta_value) {
                let keys = this.settings._list_fields.meta_value.split("\n");
                for (let i = 0; i < keys.length; i++) {
                  this.listFields[i] = {
                    key: keys[i],
                    value: ""
                  }
                }
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadTasks() {
      let queryNameAndParam = this.getQueryNameAndParam();
      let queryName = queryNameAndParam.queryName;
      let queryNameParam = queryNameAndParam.queryNameParam;

      let taskWhere = this.getTaskWhereCondition();
      let taskMetaDataWhere = this.getTaskMetaDataWhereCondition();
      if (taskMetaDataWhere) {
        taskMetaDataWhere = ", " + taskMetaDataWhere;
      }

      let query = `query {
        ${queryNameParam} {
          tasks(first: 5, page: ${this.tasks.paginatorInfo.currentPage}, orderBy: [{column: "updated_at", order: DESC}]` + taskWhere + taskMetaDataWhere + `) {
            data {
              id
              code
              name
              content
              status
              department {
                name
                code
              }
              assignee {
                uid
                name
              }
              metaData(first: 100) {
                data {
                  meta_key
                  meta_value
                }
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data[queryName] && data.data[queryName].tasks) {
              this.tasks = data.data[queryName].tasks;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadEmployees() {
      let queryNameAndParam = this.getQueryNameAndParam();
      let queryName = queryNameAndParam.queryName;
      let queryNameParam = queryNameAndParam.queryNameParam;

      let employeeWhere = this.getEmployeeWhereCondition();

      let query = `query($page: Int) {
        ${queryNameParam} {
          employees(first: 5, page: $page, orderBy: [{column: "id", order: DESC}]` + employeeWhere + `) {
            data {
              id
              uid
              name
              phone
              email
              employeeAgencyPivot {
                role
                status
              }
              departments(first: 100) {
                data {
                  id
                  code
                  name
                  employeeDepartmentPivot {
                    role
                    status
                  }
                }
              }
              thumbnail {
                id
                link
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
        }
      }`;
      ApiService.graphql(query, {page: this.employees.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data[queryName] && data.data[queryName].employees) {
              this.employees = data.data[queryName].employees;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadDepartments() {
      let queryNameAndParam = this.getQueryNameAndParam();
      let queryName = queryNameAndParam.queryName;
      let queryNameParam = queryNameAndParam.queryNameParam;

      let departmentWhere = this.getDepartmentWhereCondition();

      let query = `query {
        ${queryNameParam} {
          departments(first: 100, page: ${this.departments.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]` + departmentWhere + `) {
            data {
              id
              code
              name
              employees(first: 100) {
                data {
                  id
                  uid
                  name
                }
                paginatorInfo {
                  total
                }
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data[queryName] && data.data[queryName].departments) {
              this.departments = data.data[queryName].departments;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadSchools() {
      let queryNameAndParam = this.getQueryNameAndParam();
      let queryName = queryNameAndParam.queryName;
      let queryNameParam = queryNameAndParam.queryNameParam;

      let schoolWhere = this.getSchoolWhereCondition();

      let query = `query {
        ${queryNameParam} {
          schools(first: 5, page: ${this.schools.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]` + schoolWhere + `) {
            data {
              id
              code
              name
              subdomain
              status
              content
              created_at
              thumbnail {
                id
                link
                media_type
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data[queryName] && data.data[queryName].schools) {
              this.schools = data.data[queryName].schools;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    getSchoolWhereCondition() {
      let filters = {};

      for (let key in this.schoolFilter) {
        if (key === "name") {
          if (this.schoolFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.schoolFilter[key] + "%"
            }
          }
        } else if (key === "status") {
          if (this.schoolFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.schoolFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    getEmployeeWhereCondition() {
      let filters = {};

      for (let key in this.employeeFilter) {
        if (key === "name" || key === "email" || key === "phone") {
          if (this.employeeFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.employeeFilter[key] + "%"
            }
          }
        } else if (key === "agency_employee.status" || key === "agency_employee.role") {
          if (this.employeeFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.employeeFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    getTaskWhereCondition() {
      let filters = {};

      for (let key in this.taskFilter) {
        if (key === "name") {
          if (this.taskFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.taskFilter[key] + "%"
            }
          }
        } else {
          if (this.taskFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.taskFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    getTaskMetaDataWhereCondition() {
      let cond = [];

      for (let i = 0; i < this.filterFields.length; i++) {
        let item = this.filterFields[i];
        if (item.value !== "") {
          let where = {};
          where["meta_value"] = {
            operator: "LIKE",
            value: "%" + item.value + "%"
          }
          where["meta_key"] = {
            operator: "EQ",
            value: item.key
          }
          cond.push(`whereMetaData${i}: ` + getWhereCondition2(where));
        }
      }

      return cond.join(", ");
    },
    getDepartmentWhereCondition() {
      let filters = {};

      for (let key in this.departmentFilter) {
        if (key === "name") {
          if (this.departmentFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.departmentFilter[key] + "%"
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    resetAddEmployeeForm() {
      this.employee = {
        uid: "",
        name: "",
        email: "",
        phone: "",
        password: "",
        role: "employee",
        department_code: "",
        department_role: "",
        thumbnail: {id: 0}
      }
      this.addEmployeeMessage = "";
      this.detectedUserLoading = false;
    },
    addEmployee() {
      this.modalTitle = "Thêm nhân viên";

      let query = `mutation($uid: String, $role: String, $name: String, $phone: String, $email: String, $password: String, $thumbnail_id: Int, $department_code: String, $department_role: String) {
        addEmployeeAgency(input: {
          uid: $uid,
          role: $role,
          name: $name,
          phone: $phone,
          email: $email,
          password: $password,
          thumbnail_id: $thumbnail_id,
          department_code: $department_code,
          department_role: $department_role
        })
      }`;

      ApiService.graphql(query, this.employee)
          .then(({data}) => {
            if (data.data && data.data.addEmployeeAgency) {
              this.modalMessage = "Thêm nhân viên thành công";
              this.modalType = "success";
              closeModal("addEmployeeModal");
              openModal("messageModal");
              this.loadEmployees();
              this.resetAddEmployeeForm();
            } else {
              this.addEmployeeMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.addEmployeeMessage = response.message;
          });
    },
    updateEmployee() {
      this.modalTitle = "Sửa nhân viên";

      let query = `mutation($uid: String!, $role: String, $name: String, $phone: String, $email: String, $password: String, $thumbnail_id: Int, $department_code: String, $department_role: String) {
        updateEmployeeAgency(input: {
          uid: $uid,
          role: $role,
          name: $name,
          phone: $phone,
          email: $email,
          password: $password,
          thumbnail_id: $thumbnail_id,
          department_code: $department_code,
          department_role: $department_role
        })
      }`;

      ApiService.graphql(query, this.employee)
          .then(({data}) => {
            if (data.data && data.data.updateEmployeeAgency) {
              this.modalMessage = "Sửa nhân viên thành công";
              this.modalType = "success";
              closeModal("updateEmployeeModal");
              openModal("messageModal");
              this.loadEmployees();
              this.resetAddEmployeeForm();
            } else {
              this.addEmployeeMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.addEmployeeMessage = response.message;
          });
    },
    assignEmployee() {
      this.modalTitle = "Thêm nhân viên";

      let query = `mutation($identity: String!, $role: String!, $agency_code: String) {
        assignEmployeeAgency(role: $role, identity: $identity, agency_code: $agency_code)
      }`;

      let agency_code = this.$route.params.code ? this.$route.params.code : null;
      ApiService.graphql(query, {
        identity: this.suggestEmployee.uid,
        role: this.suggestEmployee.role,
        agency_code: agency_code
      })
          .then(({data}) => {
            if (data.data && data.data.assignEmployeeAgency) {
              this.modalMessage = "Thêm nhân viên thành công";
              this.modalType = "success";
              closeModal("assignEmployeeModal");
              openModal("messageModal");
              this.loadEmployees();
            } else {
              alert(data.errors[0].message);
            }
          })
          .catch((response) => {
            alert(response.message);
          });
    },
    changeEmployeeRole() {
      this.modalTitle = "Thay đổi quyền";

      let query = `mutation($identity: String!, $role: String!) {
        assignEmployeeAgency(role: $role, identity: $identity)
      }`;

      ApiService.graphql(query, {
        identity: this.currentEmployeeChangeRole.uid,
        role: this.currentEmployeeChangeRole.employeeAgencyPivot.role
      })
          .then(({data}) => {
            if (data.data && data.data.assignEmployeeAgency) {
              this.modalMessage = "Thay đổi quyền thành công";
              this.modalType = "success";
              closeModal("changeEmployeeRoleModal");
              openModal("messageModal");
              this.loadSchool();
            } else {
              if (data.errors) {
                this.modalMessage = data.errors[0].message;
                this.modalType = "danger";
                closeModal("changeEmployeeRoleModal");
                openModal("messageModal");
              }
            }
          })
          .catch((response) => {
            this.modalMessage = response.message;
            this.modalType = "danger";
            closeModal("changeEmployeeRoleModal");
            openModal("messageModal");
          });
    },
    setCurrentEmployeeChangeRole(employee) {
      this.currentEmployeeChangeRole.uid = employee.uid;
      this.currentEmployeeChangeRole.employeeAgencyPivot = employee.employeeAgencyPivot;
    },
    setCurrentEmployee(employee) {
      this.employee.uid = employee.uid;
      this.employee.name = employee.name;
      this.employee.email = employee.email;
      this.employee.phone = employee.phone;
      this.employee.password = "";
      this.employee.role = employee.employeeAgencyPivot.role;
      this.employee.thumbnail = employee.thumbnail;
      this.employee.departments = employee.departments;
      this.employee.department_code = "";
      this.employee.department_role = "";

      console.log("setCurrentEmployee......", this.employee);
    },
    findUserByIdentity(identity) {
      this.detectedUserLoading = true;

      let query = `mutation($identity: String!) {
        findUserByIdentity(identity: $identity) {
          uid
          email
          name
          phone
          thumbnail {
            uid
            link
          }
        }
      }`;

      ApiService.graphql(query, {identity: identity})
          .then(({data}) => {
            if (data.data && data.data.findUserByIdentity) {
              this.employee.name = data.data.findUserByIdentity.name;
              this.employee.uid = data.data.findUserByIdentity.uid;
              this.employee.email = data.data.findUserByIdentity.email;
              this.employee.phone = data.data.findUserByIdentity.phone;
              this.employee.thumbnail = data.data.findUserByIdentity.thumbnail;
              this.detectedUser = data.data.findUserByIdentity;
            } else {
              this.detectedUser = null;
            }
            this.detectedUserLoading = false;
          })
          .catch(() => {
            this.detectedUser = null;
            this.detectedUserLoading = false;
          });
    },
    addTask() {
      this.modalTitle = "Thêm công việc";

      let query = `mutation($agency_code: String, $name: String!, $content: String, $customFields: Mixed) {
        addTask(agency_code: $agency_code, name: $name, content: $content, custom_fields: $customFields) {
            id
        }
      }`;

      ApiService.graphql(query, {
        agency_code: this.agency.code,
        name: this.task.name,
        content: this.task.content,
        customFields: this.customFields
      })
          .then(({data}) => {
            if (data.data && data.data.addTask) {
              this.modalMessage = "Thêm công việc thành công";
              this.modalType = "success";
              closeModal("addTaskModal");
              openModal("messageModal");
              this.loadTasks();
            } else {
              this.addTaskMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.addTaskMessage = response.message;
          });
    },
    updateTask() {
      this.modalTitle = "Sửa công việc";

      let query = `mutation($task_code: String!, $name: String, $content: String, $customFields: Mixed) {
        updateTask(task_code: $task_code, name: $name, content: $content, custom_fields: $customFields) {
            id
        }
      }`;

      ApiService.graphql(query, {
        task_code: this.currentTask.code,
        name: this.currentTask.name,
        content: this.currentTask.content,
        customFields: this.customFields
      })
          .then(({data}) => {
            if (data.data && data.data.updateTask) {
              this.modalMessage = "Sửa công việc thành công";
              this.modalType = "success";
              closeModal("updateTaskModal");
              openModal("messageModal");
              this.loadTasks();
            } else {
              this.updateTaskMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.updateTaskMessage = response.message;
          });
    },
    deleteTask() {
      this.modalTitle = "Xóa công việc";

      let query = `mutation($id: ID!) {
        deleteTask(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.currentTask.id})
          .then(({data}) => {
            if (data.data && data.data.deleteTask) {
              this.modalMessage = "Xóa công việc thành công";
              this.modalType = "success";
              closeModal("deleteTaskModal");
              openModal("messageModal");
              this.loadAgency();
            } else {
              this.deleteTaskMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.deleteTaskMessage = response.message;
          });
    },
    removeEmployeeAgency() {
      let query = `mutation($agency_code: String, $uid: String!) {
        removeEmployeeAgency(agency_code: $agency_code, uid: $uid)
      }`;

      ApiService.graphql(query, {agency_code: this.agency.code, uid: this.employee.uid})
          .then(({data}) => {
            if (data.data && data.data.removeEmployeeAgency) {
              this.loadEmployees();
              closeModal("removeEmployeeAgencyModal");
            } else {
              this.deleteEmployeeMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.deleteEmployeeMessage = response.message;
          });
    },
    addDepartment() {
      this.modalTitle = "Thêm phòng ban";

      let query = `mutation($name: String!) {
        createDepartment(input: {
          name: $name
        }) {
          id
        }
      }`;

      ApiService.graphql(query, this.department)
          .then(({data}) => {
            if (data.data && data.data.createDepartment) {
              this.modalMessage = "Thêm phòng ban thành công";
              this.modalType = "success";
              closeModal("addDepartmentModal");
              openModal("messageModal");
              this.loadAgency();
            } else {
              this.addDepartmentMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.addDepartmentMessage = response.message;
          });
    },
    changeTaskStatus() {
      this.modalTitle = "Thay đổi trạng thái công việc";

      let query = `mutation($task_code: String!, $status: String!, $comment: String) {
        changeTaskStatus(task_code: $task_code, status: $status, comment: $comment)
      }`;

      ApiService.graphql(query, {
        task_code: this.currentTask.code,
        status: this.taskStatus,
        comment: this.changeTaskStatusComment
      })
          .then(({data}) => {
            if (data.data && data.data.changeTaskStatus) {
              this.modalMessage = "Thay đổi trạng thái công việc thành công";
              this.modalType = "success";
              closeModal("changeTaskStatusModal");
              openModal("messageModal");
              this.loadAgency();
              this.changeTaskStatusComment = "";
            } else {
              this.changeStatusTaskMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.changeStatusTaskMessage = response.message;
          });
    },
    changeTaskDepartment() {
      this.modalTitle = "Chuyển giao công việc";

      let query = `mutation($task_code: String!, $department_code: String!, $comment: String) {
        changeTaskDepartment(task_code: $task_code, department_code: $department_code, comment: $comment)
      }`;

      ApiService.graphql(query, {
        task_code: this.currentTask.code,
        department_code: this.changeTaskDepartmentDepartmentCode,
        comment: this.changeTaskDepartmentComment
      })
          .then(({data}) => {
            if (data.data && data.data.changeTaskDepartment) {
              this.modalMessage = "Chuyển giao công việc thành công";
              this.modalType = "success";
              closeModal("changeTaskDepartmentModal");
              openModal("messageModal");
              this.loadAgency();
              this.changeTaskDepartmentComment = "";
            } else {
              this.changeTaskDepartmentMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.changeTaskDepartmentMessage = response.message;
          });
    },
    changeTaskAssignee() {
      this.modalTitle = "Gán công việc";

      let query = `mutation($task_code: String!, $uid: String!, $comment: String) {
        changeTaskAssignee(task_code: $task_code, uid: $uid, comment: $comment)
      }`;

      ApiService.graphql(query, {
        task_code: this.currentTask.code,
        uid: this.changeTaskAssigneeUid,
        comment: this.changeTaskAssigneeComment
      })
          .then(({data}) => {
            if (data.data && data.data.changeTaskAssignee) {
              this.modalMessage = "Gán công việc thành công";
              this.modalType = "success";
              closeModal("changeTaskAssigneeModal");
              openModal("messageModal");
              this.loadAgency();
              this.changeTaskAssigneeComment = "";
            } else {
              this.changeTaskAssigneeMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.changeTaskAssigneeMessage = response.message;
          });
    },
    updateAgencyMetaData(meta_key, meta_value) {
      this.modalTitle = "Cài đặt";

      let query = `mutation($meta_key: String!, $meta_value: String) {
        updateAgencyMetaData(meta_key: $meta_key, meta_value: $meta_value)
      }`;

      ApiService.graphql(query, {meta_key: meta_key, meta_value: meta_value})
          .then(({data}) => {
            if (data.data && data.data.updateAgencyMetaData) {
              this.modalMessage = "Cập nhật thành công";
              this.modalType = "success";
              window.$("#messageModalOpen").click();
            } else {
              if (data.errors) {
                this.modalMessage = data.errors[0].message;
              }
              this.modalType = "danger";
              window.$("#messageModalOpen").click();
            }
          })
          .catch((response) => {
            this.modalMessage = response.message;
            this.modalType = "danger";
            window.$("#messageModalOpen").click();
          });
    },
    deleteSchool() {
      let query = `mutation($id: ID!) {
        deleteSchool(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.currentSchool.id})
          .then(({data}) => {
            if (data.data && data.data.deleteSchool) {
              closeModal("removeSchoolModal");
              this.loadSchools();
            } else {
              this.deleteSchoolMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.deleteSchoolMessage = response.message;
          });
    },
    getValueByMetaKey(key, metaData) {
      if (metaData && metaData.length) {
        for (let i = 0; i < metaData.length; i++) {
          if (key === metaData[i].meta_key) {
            return metaData[i].meta_value;
          }
        }
      }

      return "";
    },
    setCurrentTask(task) {
      this.currentTask = JSON.parse(JSON.stringify(task));
      for (let i = 0; i < this.customFields.length; i++) {
        this.customFields[i].value = this.getValueByMetaKey(this.customFields[i].key, task.metaData.data);
      }
    },
    setCurrentTaskStatus(task) {
      this.taskStatus = task.status;
    },
    setCurrentTaskDepartment(task) {
      this.changeTaskDepartmentDepartmentCode = task.department.code;
    },
    setCurrentTaskAssignee(task) {
      this.changeTaskAssigneeUid = task.assignee ? task.assignee.uid : "";
    },
    setCurrentDepartment(department) {
      this.currentDepartment = department;
    },
    handleSetMediaEvent(media, purpose) {
      if (purpose === "setThumbnailForUser") {
        this.employee.thumbnail = media;
      } else if (purpose === "setThumbnailForAgency") {
        this.agency.thumbnail = media;
      }
    },
    handleSetThumbnailEvent(purpose) {
      this.mediaPurpose = purpose;
    },
    setCurrentSchool(school) {
      this.currentSchool = school;
    },
    processTab() {
      let tab = this.$route.query.tab;
      if (tab) {
        this.tab = tab;
      }
    },
    setTab(tab) {
      this.tab = tab;
    },
    getSchoolDomain(subdomain) {
      return getSchoolDomain(subdomain);
    },
    getSubmitWhereCondition() {
      let filters = {};

      for (let key in this.submitFilter) {
        if (key === "name" || key === "email" || key === "phone" || key === "lesson_name") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.submitFilter[key] + "%"
            }
          }
        } else if (key === "is_reviewed") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.submitFilter[key]
            }
          }
        } else if (key === "reviewed_by") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.submitFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    loadSubmits() {
      let where = this.getSubmitWhereCondition();

      let query = `query {
        submitLessons(all: "yes", first: 5,page: ${this.submits.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}] ${where}) {
          data {
            id
            lesson_name
            section_name
            is_reviewed
            comment
            created_at
            course {
              name
              code
            }
            reviewer {
              name
            }
            school {
              id
              name
              subdomain
            }
            media {
              link
            }
            user {
              name
              uid
              thumbnail {
                link
              }
            }
          }
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.submitLessons) {
              this.submits = data.data.submitLessons;

              if (this.user && (this.user.role === "reviewer_admin" || this.user.role === "reviewer")) {
                this.setTab("submit");
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
  },
  mounted() {
    let token = getToken();
    if (!token) {
      this.$router.push({name: "Login"});
    }
    this.processTab();
    this.loadAgency();
    this.loadTasks();
    this.loadEmployees();
    this.loadDepartments();
    this.loadSchools();
    this.loadSubmits();
  },
  computed: {
    isAdmin() {
      let user = getUser();
      return user && (user.agency_role === "admin" || user.role === "admin");
    }
  },
  watch: {
    schoolFilter: {
      handler() {
        this.schools.paginatorInfo.currentPage = 1;
        this.loadSchools();
      },
      deep: true
    },
    employeeFilter: {
      handler() {
        this.employees.paginatorInfo.currentPage = 1;
        this.loadEmployees();
      },
      deep: true
    },
    departmentFilter: {
      handler() {
        this.departments.paginatorInfo.currentPage = 1;
        this.loadDepartments();
      },
      deep: true
    },
    taskFilter: {
      handler() {
        this.tasks.paginatorInfo.currentPage = 1;
        this.loadTasks();
      },
      deep: true
    },
    filterFields: {
      handler() {
        this.tasks.paginatorInfo.currentPage = 1;
        this.loadTasks();
      },
      deep: true
    }
  }
}
</script>
