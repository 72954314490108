<template>
  <div v-if="departments">
    <div class="form-check" v-for="(department, index) in departments" :key="unique + 'DepartmentCheckbox' + index">
      <input type="radio"
             v-model="value"
             :value="department.code"
             :id="unique + 'Department' + department.id"
             class="form-check-input">
      <label class="form-check-label" :for="unique + 'Department' + department.id">{{ department.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Departments",
  data() {
    return {
      value: ""
    }
  },
  props: {
    departments: Array,
    selectedDepartments: Array,
    unique: {
      type: String,
      default: ""
    },
    code: {
      type: String,
      default: ""
    }
  },
  methods: {
    getSelectedValue() {
      if (this.selectedDepartments) {
        for (let i = 0; i < this.selectedDepartments.length; i++) {
          for (let j = 0; j < this.departments.length; j++) {
            if (this.departments[j].code === this.selectedDepartments[i].code) {
              this.value = this.departments[j].code;
              return;
            }
          }
        }
      }
    },
    setCurrentValue(value) {
      this.value = value;
    }
  },
  created() {
    this.getSelectedValue();
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('update:code', newVal)
      }
    },
    code: {
      handler(newVal) {
        this.setCurrentValue(newVal);
      }
    },
    departments: {
      handler() {
        this.getSelectedValue();
      }
    },
    selectedDepartments: {
      handler() {
        this.getSelectedValue();
      }
    }
  }
}
</script>
