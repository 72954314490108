<template>
  <span :class="'badge rounded-pill bg-' + getClass()">{{ department }}</span>
</template>

<script>
export default {
  name: "TaskDepartmentBadge",
  props: {
    department: String
  },
  methods: {
    getClass() {
      switch (this.department) {
        default:
          return "secondary";
      }
    }
  }
}
</script>
