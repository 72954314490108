<template>
  <select :class="'form-control ' + this.class" v-model="value">
    <option value="" v-if="showEmpty">-- Người xử lý --</option>
    <optgroup :label="department.name" :key="unique + department.id" v-for="department in departments">
      <template v-if="department.employees && department.employees.data">
        <option :value="employee[field]" :key="unique + employee.uid" v-for="employee in department.employees.data">{{ employee.name }}</option>
      </template>
    </optgroup>
  </select>
</template>

<script>

export default {
  name: "TaskAssigneeSelect",
  props: {
    class: {
      type: String,
      default: ""
    },
    showEmpty: {
      type: Boolean,
      default: false
    },
    unique: String,
    change: {
      type: String,
      default: ""
    },
    field: {
      type: String,
      default: "uid"
    },
    departments: Array
  },
  data() {
    return {
      value: ""
    }
  },
  methods: {
    setCurrentValue(value) {
      this.value = value;
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('update:change', newVal)
      }
    },
    change: {
      handler(newVal) {
        this.setCurrentValue(newVal);
      }
    }
  }
}
</script>
