<template>
  <div class="spinner-border spinner-border-sm loading" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>
