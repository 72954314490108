<template>
  <div v-if="roles" class="d-inline-flex">
    <div class="form-check me-3" v-for="(role, index) in roles" :key="unique + 'AgencyRole' + index">
      <input type="radio"
             v-model="value"
             :value="role.value"
             :id="unique+ 'AgencyRole' + role.value"
             class="form-check-input">
      <label class="form-check-label" :for="unique + 'AgencyRole' + role.value">{{ role.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgencyRoles",
  props: {
    selectedRole: String,
    unique: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      value: "",
      roles: [
        {name: "Admin", value: "admin"},
        {name: "Nhân viên", value: "employee"}
      ]
    }
  },
  methods: {
    getSelectedValue() {
      this.value = this.selectedRole;
    }
  },
  created() {
    this.getSelectedValue();
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('update:role', newVal)
      }
    },
    selectedRole: {
      handler() {
        this.getSelectedValue();
      }
    }
  }
}
</script>
