<template>
  <select :class="'form-control ' + this.class" v-model="value">
    <option value="" v-if="showEmpty">-- Trạng thái --</option>
    <option :value="status.status" :key="unique + status.status" v-for="status in statuses">{{ status.name }}</option>
  </select>
</template>

<script>
import {taskStatuses} from "@/core/services/utils.service";

export default {
  name: "TaskStatusSelect",
  props: {
    unique: String,
    class: {
      type: String,
      default: ""
    },
    showEmpty: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      value: "",
      statuses: []
    }
  },
  methods: {
    setCurrentValue(value) {
      this.value = value;
    }
  },
  mounted() {
    this.statuses = taskStatuses;
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('update:status', newVal)
      }
    },
    status: {
      handler(newVal) {
        this.setCurrentValue(newVal);
      }
    }
  }
}
</script>
