<template>
  <span class="badge rounded-pill bg-light text-dark">{{ assignee }}</span>
</template>

<script>
export default {
  name: "TaskAssignee",
  props: {
    assignee: String
  }
}
</script>
